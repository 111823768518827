// main colors
$black: #000000;
$white: #ffffff;
$theme-color: #008036;
$theme-20: rgba($theme-color, 0.20);
$theme-5: rgba($theme-color, 0.05);
$title-color: #222222;
$content-color: #777777;
$border-color: #dddddd;
$theme-green: #198754;
$ratings: #FFBA49;


// grey shades
$grey-light: #EDEFF4;
$grey-light-60: rgba($grey-light, 0.60);
$grey-light-45: rgba($grey-light, 0.45);
$grey-light-40: rgba($grey-light, 0.40);
$grey-light-25: rgba($grey-light, 0.25);


// gradient 
$gradient-1: linear-gradient(180deg, rgba(237, 239, 244, 0.3) 0%, #EDEFF4 100%);
$gradient-2: linear-gradient(180deg, rgba(230, 230, 239, 0.3) 0%, #E6E6EF 100%);
$gradient-3: linear-gradient(270.08deg, rgba($white, 0.33) 0.07%,$white 81.25%);
$gradient-4: linear-gradient(1.82deg, $white 1.55%, rgba($white, 0.15) 98.46%);


// shadow 
$shadow-1: 0px 0px 4px rgba(0, 0, 0, 0.15);
$shadow-2: 0px 1px 3px rgba(0, 0, 0, 0.03);
$color-shadow: 0px 3px 3px rgba(130, 187, 205, 0.46);