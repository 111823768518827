/*=====================
  2.3 Animation CSS start
==========================*/

// wishlist animation
// fireworks
$fireworks--width: 3px;
$fireworks--height: 3px;
$fireworks--border: calc($fireworks--width/2);
.effect-group {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    .effect {
        display: block;
        position: absolute;
        top: 38%;
        left: 50%;
        width: 20px;
        transform-origin: 0px 2px;
        &:nth-child(2) {
            transform: rotate(72deg);
        }
        &:nth-child(3) {
            transform: rotate(72 * 2deg);
        }
        &:nth-child(4) {
            transform: rotate(72 * 3deg);
        }
        &:nth-child(5) {
            transform: rotate(72 * 4deg);
        }
        &:before {
            content: "";
            display: block;
            position: absolute;
            right: 0;
            border-radius: $fireworks--border;
            height: $fireworks--height;
            background: $content-color;
        }
        &:after {
            content: "";
            display: block;
            position: absolute;
            top: 10px;
            right: 10%;
            border-radius: 50%;
            width: $fireworks--width;
            height: $fireworks--height;
            background: $theme-color;
            transform: scale(0, 0);
        }
    }
}

// fireworks animations
@keyframes fireworkLine {
    0% {
        right: 20%;
        transform: scale(0, 0);
    }
    25% {
        right: 20%;
        width: 6px;
        transform: scale(1, 1);
    }
    35% {
        right: 0;
        width: 35%;
    }
    70% {
        right: 0;
        width: 4px;
        transform: scale(1, 1);
    }
    100% {
        right: 0;
        transform: scale(0, 0);
    }
}
@keyframes fireworkPoint {
    30% {
        transform: scale(0, 0);
    }
    60% {
        transform: scale(1, 1);
    }
    100% {
        transform: scale(0, 0);
    }
}


@keyframes zoomIn {
    from {
        opacity: 0;
        -webkit-transform: scale3d(0.6, 0.6, 0.6);
        transform: scale3d(0.6, 0.6, 0.6);
    }
    50% {
        opacity: 1;
    }
}